import {ReactComponent as Logo} from '../assets/logo.svg';

export default function Navbar() {
    return(
        <div className="nav">
            <div className="nav-inner">
                <div className="dashboard-logo">
                    <Logo id="logo"/>
                    <h1 className="text-logo">Squawk appella</h1>
                </div>
                <div className="welcome-message">
                    <p className="text-accent">Hi Matt</p>
                </div>
            </div>
        </div>
    );
}