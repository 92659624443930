import {ReactComponent as ClockIcon} from '../assets/clock.svg';
import Question from "../components/question";
import Clock from "../components/clock";
import { useState } from 'react';

export default function Round(props) {
    const [activeQuestion, setActiveQuestion] = useState(0)

    function getQuestionState(displayedAnswers, roundId, questionId){
        const answerCount = displayedAnswers.filter((answer) => answer.roundId === roundId && answer.questionId === questionId).map((answer) => {
            let total = 0;
            if (answer.artist !== '') { total++}
            if (answer.title !== '') {total++}
            return total;
        });
        
        switch(answerCount[0]){
            case 1:
                return 'HALF';
            case 2: 
            return 'COMPLETE';
            case 0:
            default:
                return 'NONE';
        }
    };

    return (
        <div className='dashboard-main'>
            <div className='main-inner'></div>
                <div className='meta-container'>
                    <h2 className='text-title'>{props.name}</h2>
                    <Clock startTime={new Date(2022, 10, 5, 14, 22, 11)}/>
                </div>
                <div className='quiz-container'>
                    {props.questions.map((q, idx) => (
                        <Question 
                            key={idx}
                            questionIdx={idx}
                            name={q.name} 
                            src={q.url}
                            onClick={() => setActiveQuestion(idx)}
                            checkAnswer={props.checkAnswer}
                            questionState={getQuestionState(props.displayedAnswers, props.id, idx)}
                            answerDisplay={props.displayedAnswers.filter((answer) => answer.questionId === idx)[0]}
                            active={idx === activeQuestion ? "active" : ""}/>
                    ), this)}
                </div>
        </div>
    );
}