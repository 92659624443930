import React from "react";
import { useEffect, useState } from 'react';
import Round from "../components/round";
import Navbar from "../components/navbar";
import RoundSidebar from "../components/roundSidebar";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function GameplayPage() {
    const [round, setRound] = useState({ id: 0, roundName:'', questions:[]})
    const [play, setPlay] = useState({ id: 0, displayedAnswers:[], rounds: []})
    const [gameData, setGameData] = useState({ id: 0, name:'' });
    const [searchParams] = useSearchParams();

    const navigate = useNavigate()

    useEffect(() => {
        async function getGameMetadata(){
            setGameData(
                {
                    id: 0,
                    name: 'Squawk Like an Egyptian',
                }
            );
        };

        async function getRound() {
            const roundNum = parseInt(searchParams.get('round'));
            const roundName = 'Round 0' + (roundNum+1)

            setRound(
                {
                    id: roundNum,
                    roundName: roundName,
                    questions: [
                        {
                            name: "Question 01",
                            song_id: 0,
                            url: "https://storage.googleapis.com/chickensongs-b5e3d.appspot.com/songs/OEpGBfMZ.mp3",
                            answers: [
                                {
                                    answerType: "TITLE",
                                    answerHash: "def",
                                    answerText: "If it takes a lifetime"
                                },
                                {
                                    answerType: "ARTIST",
                                    answerHash: "abc",
                                    answerText: "Jason Isbell"
                                },
                            ],
                        },
                        {
                            name: "Question 02",
                            song_id: 0,
                            url: "https://storage.googleapis.com/chickensongs-b5e3d.appspot.com/songs/OEpGBfMZ.mp3",
                            answers: [],
                        },
                        {
                            name: "Question 03",
                            song_id: 0,
                            url: "https://storage.googleapis.com/chickensongs-b5e3d.appspot.com/songs/OEpGBfMZ.mp3",
                            answers: [],
                        },
                        {
                            name: "Question 04",
                            song_id: 0,
                            url: "https://storage.googleapis.com/chickensongs-b5e3d.appspot.com/songs/OEpGBfMZ.mp3",
                            answers: [],
                        },
                        {
                            name: "Question 05",
                            song_id: 0,
                            url: "https://storage.googleapis.com/chickensongs-b5e3d.appspot.com/songs/OEpGBfMZ.mp3",
                            answers: [],
                        },
                        {
                            name: "Question 06",
                            song_id: 0,
                            url: "https://storage.googleapis.com/chickensongs-b5e3d.appspot.com/songs/OEpGBfMZ.mp3",
                            answers: [],
                        },
                        {
                            name: "Question 07",
                            song_id: 0,
                            url: "https://storage.googleapis.com/chickensongs-b5e3d.appspot.com/songs/OEpGBfMZ.mp3",
                            answers: [],
                        },
                    ],
                }
            );
        };

        async function getPlay(){
            setPlay(
                {
                    id: 0,
                    gameId: 0,
                    rounds: [
                        {
                            roundId: 0,
                            roundName: "Round 01",
                        },
                        {
                            roundId: 1,
                            roundName: "Round 02",
                        },
                        {   
                            roundId: 2,
                            roundName: "Round 03",
                        },
                    ],
                    displayedAnswers: [
                        {
                            roundId: 0,
                            questionId: 1,
                            artist: 'Bob',
                            title: 'Bobs big song',
                        },
                    ],
                }
            );
        };

        getGameMetadata();
        getPlay();
        getRound();
    }, [searchParams]);

    function compareAnswer(attempt, answer){
        // For multi-answer, we use ; to separate
        const answers = answer.split(';');
        return answers.some((candidate) => {
            // Clean the input text
            const cleanText = attempt.trim().toLowerCase().replace('\'', '').replace(',', '');
            const cleanAnswer = candidate.trim().toLowerCase().replace('\'', '').replace(',', '');
            return cleanText === cleanAnswer;
        });
    };

    function checkAnswer(answerType, attempt, questionIdx){
        if(answerType !== "ARTIST" && answerType !== "TITLE"){
            return;
        }
        if(attempt === ""){
            return;
        }
        if(questionIdx < 0 || questionIdx > round.questions.length){
            console.log("Warning, attempting to check answer on invalid question index " + questionIdx);
            return;
        }

        const correct = round.questions[questionIdx].answers.filter(
            (answer) => answer.answerType === answerType).map(
                (answer) => answer.answerHash).some(
                    (answerHash) => compareAnswer(attempt, answerHash));
        if(correct){
            // TODO: call UpdatePlay with the new correct answer, then update the local Play object with the one returned from UpdatePlay()
            console.log("Answer Correct!");
        }
    };

    function nextRound(){
        // TODO: UpdatePlay with stats
        if(round.id >= play.rounds.length - 1){
            // Already at last round
            return;
        }
        navigate(`/play/${gameData.id}?round=${++round.id}`);
    };

    function navToRound(round){
        navigate(`/play/${gameData.id}?round=${round}`);
    };

    function exitQuiz(){
        // TODO: UpdatePlay with pause time, stats
        navigate(`/games`);
    };

    return (
        <section className="dashboard-container">
            <div className="dashboard-main-container">
                <Navbar/>
                <Round 
                    id={round.id} 
                    name={round.roundName} 
                    questions={round.questions} 
                    displayedAnswers={play.displayedAnswers}
                    checkAnswer={checkAnswer}
                /> 
            </div>
            <div className="dashboard-sidebar-container">
                <RoundSidebar 
                    gameName={gameData.name} 
                    activeRound={round.id} 
                    rounds={play.rounds}
                    onExitClick={exitQuiz}
                    onNextClick={nextRound}
                    onRoundClick={navToRound}
                />
            </div>
        </section>
    );
}