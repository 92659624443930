import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import GamesListPage from './pages/gamesList';
import GameplayPage from './pages/gamePlay';
import GameSummaryPage from './pages/gameSummary';

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Routes>
            <Route exact path='/games' element={<GamesListPage/>}>
            </Route>
            <Route path='/play/:playId' element={<GameplayPage/>}>
            </Route>
            <Route path='/play/:playId/summary' element={<GameSummaryPage/>}>
            </Route>
          </Routes>
        </div>
        </Router>      
    </div>
  );
}

export default App;
