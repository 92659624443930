import {ReactComponent as PlayIcon} from '../assets/play.svg';
import {ReactComponent as PauseIcon} from '../assets/pause.svg';
import {ReactComponent as MiniWaveIcon} from '../assets/mini-wave.svg';
import { useRef, useState } from 'react';

import {ReactComponent as CheckIcon} from '../assets/check.svg';
import {ReactComponent as CircleIcon} from '../assets/circle.svg';
import {ReactComponent as HalfCircleIcon} from '../assets/half-circle.svg';

export default function Question(props) {

    const titleInputRef = useRef(null);
    const artistInputRef = useRef(null);

    const [audioPlaying, setAudioPlaying] = useState(false);

    function renderIcon(questionState){
        switch(questionState) { 
            case 'NONE':
                return <CircleIcon id='circle'/>
            case 'HALF':
                return <HalfCircleIcon id='half-circle'/>
            case 'COMPLETE':
            default:
                return <CheckIcon id='check'/>
          };
    };

    function play() {
        var audio = document.getElementById("audio");
        
        if (!audio.paused){
            audio.pause();
            setAudioPlaying(false);
            return;
        }

        audio.play();
        setAudioPlaying(true);
    };

    return (
        <div className={`game-list-entry ${props.active}`} onClick={props.onClick}>
            <div className='question-container'>
                <div className='play-container' onClick={() => play()}>
                    {audioPlaying ? <PauseIcon id="pause"/> : <PlayIcon id="play"/>}
                    <p className="text-large text-white">{props.name}</p>
                </div>

                <div className='meta-container'>
                    <div className='mini-wave-container'>
                        <MiniWaveIcon id="mini-wave"/>
                    </div>
                    <p className="text-large text-white-50">02:45</p>
                    <audio id="audio" preload="auto" src={props.src}></audio>
                    <div className='icon-container'>
                        {renderIcon(props.questionState)}
                    </div>
                </div>
            </div>

            <div className='submit-container'>
                <form>
                    <input 
                        id="title-input"
                        type="text" 
                        ref={titleInputRef}
                        placeholder={props.answerDisplay?.title ? props.answerDisplay.title : 'Song'} 
                        className='text-center' 
                        disabled={props.answerDisplay && props.answerDisplay.title !== ''}
                        onChange={() => props.checkAnswer("TITLE", titleInputRef.current.value, props.questionIdx)}
                    />
                    <input 
                        id="artist-input"
                        type="text" 
                        ref={artistInputRef}
                        placeholder={props.answerDisplay?.artist ? props.answerDisplay.artist : 'Artist'}
                        className='text-center'
                        disabled={props.answerDisplay &&  props.answerDisplay.artist !== ''}
                        onChange={() => props.checkAnswer("ARTIST", artistInputRef.current.value, props.questionIdx)}
                    />
                </form>
            </div>
        </div>
    );
}