import {ReactComponent as WaveIcon} from '../assets/wave.svg';

export default function GameListSidebar(props) {
    return(
        <div className="dashboard-sidebar">
            <div className="sidebar-inner">
                <h2 className="text-title text-center">Now Playing</h2>
                <div className="wave-container">
                    <WaveIcon id="wave"/>
                </div>
                <div className="main-content text-center">
                    <p className='text-large'>{props.name}</p>
                    <p className='text-white-50'>Playtime {props.playMins} mins</p>
                    <div className='divider-line'></div>
                    <p className='text-white-50'>
                        12 artists and 5 songs
                        <br/>
                        still to guess
                    </p>
                    <div className='cta-container'>
                        <a>Resume</a>
                    </div>
                    <div className='cta-link'>
                        <a>Restart</a>
                    </div>
                </div>
            </div>
        </div>
    );
}