import React from "react";
import { useEffect, useState } from 'react';
import GameListEntry from "../components/gameListEntry";
import Navbar from "../components/navbar";
import GameListSidebar from "../components/gameListSidebar";
import { useNavigate } from "react-router-dom";

export default function GamesListPage() {
    const [games, setGames] = useState([{ id: 0, name:'' }])
    const [activeGameIdx, setActiveGameIdx] = useState(0)
    const navigate = useNavigate()

    useEffect(() => {
        async function getGamesList() {
            setGames(
                [
                    {
                        id: 0,
                        name: 'Squawk Like an Egyptian',
                        playtime_mins: 45,
                        playState: 'PAUSED',
                    },
                    {
                        id: 1,
                        name: 'Chicken minis',
                        playtime_mins: 15,
                        playState: 'NEVER',
                    },
                    {
                        id: 2,
                        name: 'Doneskis',
                        playtime_mins: 25,
                        playState: 'COMPLETE',
                    },
                ]
            );
            setActiveGameIdx(0);
        };
        getGamesList();
    }, []);

    return (
        <section className="dashboard-container">
            <div className="dashboard-main-container">
                <Navbar/>
                <div className='dashboard-main'>
                    <div className='main-inner'>
                        <div className='meta-container'>
                            <h2 className='text-title'>My quizzes</h2>
                        </div>
                        <div className='quiz-container'>
                            {games.map((item) => (
                                    <GameListEntry 
                                        key={item.id}
                                        name={item.name}
                                        playMins={item.playtime_mins}
                                        /* TODO: item.id is the gameId, not the playId. Need to generate a playId and pass */
                                        onClick={() => navigate(`/play/${item.id}?round=0`)}
                                        playState={item.playState}/>
                            ), this)}
                        </div>
                    </div>
                </div>                
            </div>
            <div className="dashboard-sidebar-container">
                <GameListSidebar name={games[activeGameIdx].name} playMins={games[activeGameIdx].playtime_mins}/>
            </div>
        </section>
    );
}